import * as Yup from 'yup';
import { Form, useFormik, FormikProvider } from 'formik';
import { useState, useEffect, SyntheticEvent } from 'react';

import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { LoadingButton } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Tab,
  Card,
  Grid,
  Stack,
  Button,
  MenuItem,
  useTheme,
  Accordion,
  TextField,
  IconButton,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useFormTranslate from 'src/hooks/useFormTranslate';
import useNotifications from 'src/hooks/useNotifications';

import eventBus, { EventTypes } from 'src/utils/eventBus';
import {
  dataUrlToFile,
  containerHasMimetype,
  getAllContainerFilesBase64,
} from 'src/utils/fileUtils';

import { useSelector } from 'src/redux/store';
import documentService from 'src/services/documentService';
import { ModuleTypes } from 'src/@types/module/enums/moduleTypes';
import { Permissions } from 'src/@types/auth/constants/permissions';
import { FileExtensions } from 'src/@types/fileView/enums/fileExtensions';
import { clearSignatureReqModelState } from 'src/redux/slices/signaturePadReqModel';
import { setSignPosition, clearSignPosition } from 'src/redux/slices/signaturePosition';
import { DocumentSignatureType } from 'src/@types/documents/enums/documentSignatureTypes';
import {
  setTab,
  SignType,
  setShowTabs,
  setSignType,
  setinitialState,
} from 'src/redux/slices/documentSigner';
import {
  DSFile,
  AcknowledgeRequest,
  ApproveInitRequest,
  SmartIdInitSignatureRequest,
  MobileIdInitSignatureRequest,
  EParakstsInitSignatureRequest,
} from 'src/@types/documents/types';
import {
  removeSession,
  approveDocument,
  signDocumentOnPad,
  acknowledgeDocument,
  initSmartIdSignatureSession,
  initMobileIdSignatureSession,
  initEParakstsSignatureSession,
} from 'src/redux/slices/signatureSession';

import Tip from 'src/components/Tip';
import ModuleCheck from 'src/components/access-checks/ModuleCheck';
import PermissionCheck from 'src/components/access-checks/PermissionCheck';

import ControlCodePopup from 'src/sections/auth/control-code/ControlCodePopup';

import DocumentVoidDialog from './DocumentVoidDialog';
import DocumentSmartIdSign from './DocumentSmartIdSign';
import DocumentMobileIdSign from './DocumentMobileIdSign';
import DocumentSignaturePad from './DocumentSignaturePad';
import DocumentEParakstsSign from './DocumentEParakstsSign';
import DocumentRejectionDialog from './DocumentRejectionDialog';

// ----------------------------------------------------------------------

type FormValues = {
  timestamp: string;
  stampPage: string;
};

export enum TimeStamps {
  TopLeft = 'TOP_LEFT',
  BottomLeft = 'BOTTOM_LEFT',
  TopRight = 'TOP_RIGHT',
  BottomRight = 'BOTTOM_RIGHT',
}

export enum TimeStampPages {
  First = 'FIRST',
  Last = 'LAST',
}

export enum Tabs {
  MobileId = 'MOBILE_ID',
  SmartId = 'SMART_ID',
  EParaksts = 'EPARAKSTS',
  Approve = 'APPROVE',
  Acknowledge = 'ACKNOWLEDGE',
  SignaturePad = 'SIGNATURE_PAD',
}

export default function DocumentSigning() {
  const { t, i18n } = useLocales();
  const theme = useTheme();
  const { user } = useAuth();
  const [submitMobileIdTrigger, setSubmitMobileIdTrigger] = useState(0);
  const [submitSmartIdTrigger, setSubmitSmartIdTrigger] = useState(0);
  const [submitEParakstsTrigger, setSubmitEParakstsTrigger] = useState(0);
  const [allowSign, setAllowSign] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [canSign, setCanSign] = useState(false);
  const [canAcknowledge, setCanAcknowledge] = useState(false);
  const isMountedRef = useIsMountedRef();
  const { selectedId, document } = useSelector((state) => state.document);
  const { error, sessionId } = useSelector((state) => state.signatureSession);
  const { settings } = useSelector((state) => state.company);
  const isMobile = useResponsive('down', 'sm');
  const { enqueueSuccess, enqueueError } = useNotifications();
  const [isRejectionDialogOpen, setIsRejectionDialogOpen] = useState(false);
  const [loginMethod, setLoginMethod] = useState<string | null>(null);
  const { signers, signType, tab, showTabs } = useSelector((state) => state.documentSigner);
  const [canUserSelectTimestampPosition, setCanUserSelectTimestampPosition] = useState(true);
  const [canUserSelectTimestampPagePosition, setCanUserSelectTimestampPagePosition] =
    useState(true);
  const [needSign, setNeedSign] = useState(false);
  const [commentValue, setCommentValue] = useState<string | null>(null);

  const handleCommentValueChange = (event: any) => {
    const { value } = event.target;
    if (value.length <= 100) {
      setCommentValue(value);
    }
  };

  const {
    stamp,
    x,
    y,
    xText,
    yText,
    page,
    finalWidth,
    finalHeight,
    textStamp,
    textWidth,
    textHeight,
    isSigningFinished,
  } = useSelector((state) => state.signaturePadReqModel);
  const {
    x: positionX,
    y: positionY,
    page: signPage,
  } = useSelector((state) => state.signaturePosition);

  useEffect(() => {}, [positionX, positionY]);

  const handleTabChange = (_: SyntheticEvent, tab: Tabs) => {
    setTab(tab);
  };

  const handleSignPosition = () => {
    setSignPosition(selectedId as number, 100, 100, 1);
  };

  useEffect(() => {
    setinitialState();

    return () => {
      // when sign positioning is not finished with sign api call on close component current sign & signPositioning is cleared
      if (!isSigningFinished) {
        clearSignatureReqModelState();
      }
      clearSignPosition();
    };
  }, []);

  useEffect(() => {
    getNeedSign(selectedId).then();
  }, [selectedId, isRejectionDialogOpen]);

  useEffect(() => {
    const documentSignedSubscription = eventBus.subscribe(EventTypes.DocumentSigned, () => {
      enqueueSuccess('documents.snackbar.signedSuccessfully');
    });

    const forbidSignSubscription = eventBus.subscribe(EventTypes.ForbidSign, () => {
      setAllowSign(false);
    });

    const loginMethodLocalStorage = localStorage.getItem('loginMethod');
    setLoginMethod(loginMethodLocalStorage);

    return () => {
      documentSignedSubscription.unsubscribe();
      forbidSignSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (signers.length !== 0) {
      setAvailableTabs();
    }
  }, [loginMethod, signers, document, canAcknowledge]);

  useEffect(() => {}, [signers]);

  useEffect(() => {
    // what's the main point of this useEffect?
    const signerIndex = signers.findIndex((s) => s.email === document?.ownerEmail);
    const isSigned = signers.some((s) => s.email === user?.email && s.signed);

    if (signerIndex === -1) {
      setAllowSign(false);
      setAvailableTabs();
    }

    if (signerIndex > -1 && !isSigned) {
      setAllowSign(true);
      setAvailableTabs();
    }

    if (canAcknowledge) {
      setAllowSign(true);
    }

    // when signers change, with this we see if the signer can sign the doc
    if (document) {
      getNeedSign(document.id).then();
    }
  }, [signers, canAcknowledge]);

  useEffect(() => {
    if (signers.length === 1 && isOwner()) {
      if (loginMethod) {
        if (document?.extension !== 'pdf') {
          setSignType(SignType.onlyQualified);
          setShowTabs({ ...showTabs, [loginMethod.toString()]: true, APPROVE: false });
        }
      }
    }
    if (tab !== Tabs.MobileId && tab !== Tabs.SmartId && tab !== Tabs.EParaksts) {
      clearSignPosition();
    }
  }, [tab]);

  const isOwner = () => user?.email === document?.ownerEmail;

  useEffect(() => {}, [allowSign]);

  const setAvailableTabs = () => {
    if (loginMethod) {
      // Acknowledge

      if (signType === SignType.handWrittenSign) {
        setTab(Tabs.SignaturePad);
        setShowTabs({
          ...showTabs,
          MOBILE_ID: false,
          SMART_ID: false,
          EPARAKSTS: false,
          [loginMethod.toString()]: false,
          APPROVE: false,
          SIGNATURE_PAD: true,
        });
      } else if (canAcknowledge) {
        setShowTabs({
          ...showTabs,
          [loginMethod.toString()]: false,
          APPROVE: false,
          ACKNOWLEDGE: true,
          SIGNATURE_PAD: false,
        });
        setTab(Tabs.Acknowledge);
        // Sign, Approve
      } else {
        // Document extension => pdf
        if (document?.extension === 'pdf') {
          // Login method => qualified
          if (loginMethod !== Tabs.Approve) {
            if (signers.length === 1 && isOwner()) {
              if (signType === SignType.onlyApprove) {
                setTab(Tabs.Approve);
                setShowTabs({
                  ...showTabs,
                  [loginMethod.toString()]: false,
                  APPROVE: true,
                  SIGNATURE_PAD: false,
                });
              } else {
                setTab(loginMethod as Tabs);
                setShowTabs({
                  ...showTabs,
                  [loginMethod.toString()]: true,
                  APPROVE: false,
                  SIGNATURE_PAD: false,
                });
              }
            } else {
              if (qualifiedSignatureAllowed()) {
                setShowTabs({
                  ...showTabs,
                  [loginMethod.toString()]: true,
                  APPROVE: false,
                  SIGNATURE_PAD: false,
                });
                setTab(loginMethod as Tabs);
              } else if (signType === SignType.onlyApprove) {
                setShowTabs({
                  ...showTabs,
                  [loginMethod.toString()]: false,
                  APPROVE: true,
                  SIGNATURE_PAD: false,
                });
                setTab(Tabs.Approve);
              }
            }
            // Login method => NOT qualified
          } else {
            if (signers.length === 1 && isOwner()) {
              if (signType === SignType.onlyApprove) {
                setShowTabs({
                  ...showTabs,
                  MOBILE_ID: false,
                  SMART_ID: false,
                  EPARAKSTS: false,
                  APPROVE: true,
                  SIGNATURE_PAD: false,
                });
                setTab(Tabs.Approve);
              } else {
                setTab(Tabs.MobileId);
                setShowTabs({
                  ...showTabs,
                  MOBILE_ID: true,
                  SMART_ID: true,
                  EPARAKSTS: true,
                  APPROVE: false,
                  SIGNATURE_PAD: false,
                });
              }
            } else {
              // kaip cia veiks
              if (qualifiedSignatureAllowed()) {
                setTab(Tabs.MobileId);
                setShowTabs({
                  ...showTabs,
                  MOBILE_ID: true,
                  SMART_ID: true,
                  EPARAKSTS: true,
                  APPROVE: false,
                  SIGNATURE_PAD: false,
                });
              } else {
                setTab(Tabs.Approve);
                setShowTabs({ ...showTabs, APPROVE: true });
              }
            }
          }
          // Document extension => NOT pdf
        } else {
          if (loginMethod !== Tabs.Approve) {
            setTab(loginMethod as Tabs);
            setShowTabs({
              ...showTabs,
              [loginMethod.toString()]: true,
              APPROVE: false,
              SIGNATURE_PAD: false,
            });
          } else {
            setSignType(SignType.onlyQualified);
            setTab(Tabs.MobileId);
            setShowTabs({
              ...showTabs,
              MOBILE_ID: true,
              SMART_ID: true,
              EPARAKSTS: true,
              APPROVE: false,
              SIGNATURE_PAD: false,
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    if (error) {
      enqueueError(error);
    }
  }, [error]);

  async function getNeedSign(id: number | null) {
    if (!id) {
      setAllowSign(false);
      return;
    }
    try {
      const data = await documentService.getNeedSign(id);
      setAllowSign(data.needSign);
      setCanSign(data.canSign);
      setCanAcknowledge(data.canAcknowledge);
      setNeedSign(data.needSign);
    } catch (error) {
      setAllowSign(false);
      setCanSign(false);
      setCanAcknowledge(false);
    }
  }

  const clearSession = async () => {
    setAllowSign(false);
    await removeSession(sessionId);
  };

  useEffect(() => {}, [allowSign]);

  const validationSchema = Yup.object().shape({
    timestamp: Yup.string().required(),
    stampPage: Yup.string().required(),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      timestamp: TimeStamps.TopLeft,
      stampPage: TimeStampPages.First,
    },
    validationSchema,

    onSubmit: () => {
      if (!canSign) {
        return;
      }
      submitTriggerNext();
    },
    onReset: async () => {
      setAllowSign(false);
    },
  });

  const { values, handleSubmit, getFieldProps, setFieldValue, resetForm, setSubmitting } = formik;

  useFormTranslate(formik);

  function submitTriggerNext() {
    switch (tab) {
      case Tabs.MobileId:
        setSubmitMobileIdTrigger(submitMobileIdTrigger + 1);
        break;
      case Tabs.SmartId:
        setSubmitSmartIdTrigger(submitSmartIdTrigger + 1);
        break;
      case Tabs.EParaksts:
        setSubmitEParakstsTrigger(submitEParakstsTrigger + 1);
        break;
      case Tabs.Approve:
        submitValues({ signatureType: DocumentSignatureType.Unqualified }).then();
        break;
      case Tabs.Acknowledge:
        submitValues({ signatureType: DocumentSignatureType.Acknowledge }).then();
        break;
      case Tabs.SignaturePad:
        submitValues({ signatureType: DocumentSignatureType.Handwritten }).then();
        break;

      default:
        break;
    }
  }

  function qualifiedSignatureAllowed() {
    return !document?.isOnlyApprove;
  }

  const handleRejectionDialogClose = () => {
    setIsRejectionDialogOpen(false);
  };

  const submitValues = async (idValues: any = null) => {
    if (!document) {
      return;
    }

    setIsLoading(true);
    
    try {
      // api eparaksts with sign comment
      if(commentValue && tab === Tabs.EParaksts ) {
        await documentService.saveComment(document.id, commentValue );
      }

      const initSign = await documentService.getInitSign(
        selectedId as number,
        idValues.signatureType
      );
      if (!initSign || initSign?.isBlocked) {
        setIsLoading(false);
        enqueueError('documents.snackbar.documentIsBeingSigned');
        setAllowSign(false);
        return;
      }

      const initFile = await dataUrlToFile(
        `data:application/zip;base64,${initSign.file}`,
        document.name,
        'application/zip'
      );
      let files: DSFile[];
      if (
        [FileExtensions.Asic, FileExtensions.Bdoc, FileExtensions.Edoc].includes(
          document.extension as FileExtensions
        ) &&
        !(await containerHasMimetype(initFile))
      ) {
        const zipFiles = await getAllContainerFilesBase64(initFile);
        files = zipFiles.map((zf) => ({ content: zf.content, name: zf.name }) as DSFile);
      } else {
        files = [
          {
            content: initSign.file,
            name: document.name || '',
          } as DSFile,
        ];
      }

      const timestampLogo = settings?.timestampImage || undefined;
      const code = settings?.code || '';
      const address = settings?.address || '';
      switch (tab) {
        case Tabs.MobileId:
          const mobileIdData = {
            fileExtension: document.extension ?? FileExtensions.Pdf,
            documentId: selectedId as number,
            personalCode: idValues.inn,
            phone: idValues.phone,
            files,
            documentIds: [document.id],
            timestampPosition: values.timestamp,
            timestampImage: timestampLogo,
            companyCode: document.extension === FileExtensions.Adoc ? code : undefined,
            companyAddress: document.extension === FileExtensions.Adoc ? address : undefined,
            companyName: document.extension === FileExtensions.Adoc ? document.name : undefined,
            language: i18n.language,
            documentName: document.name,
            timestampPage: values.stampPage,
            signerPosition: idValues.position,
            signerSubdivision: idValues.subdivision,
            signCoordinates: [positionX, positionY, signPage],
            comment: commentValue,
          } as MobileIdInitSignatureRequest;

          await initMobileIdSignatureSession(mobileIdData);
          clearSignPosition();
          break;

        case Tabs.SmartId:
          const smartIdData = {
            fileExtension: document.extension ?? FileExtensions.Pdf,
            documentId: selectedId as number,
            personalCode: idValues.personalCode,
            countryCode: idValues.countryCode,
            files,
            documentIds: [document.id],
            timestampPosition: values.timestamp,
            timestampImage: timestampLogo,
            companyCode: document.extension === FileExtensions.Adoc ? code : undefined,
            companyAddress: document.extension === FileExtensions.Adoc ? address : undefined,
            companyName: document.extension === FileExtensions.Adoc ? document.name : undefined,
            language: i18n.language,
            documentName: document.name,
            timestampPage: values.stampPage,
            signerPosition: idValues.position,
            signerSubdivision: idValues.subdivision,
            signCoordinates: [positionX, positionY, signPage],
            comment: commentValue,
          } as SmartIdInitSignatureRequest;
          await initSmartIdSignatureSession(smartIdData);
          clearSignPosition();
          break;

        case Tabs.EParaksts:
          const eParakstsData = {
            fileExtension: document.extension ?? FileExtensions.Pdf,
            documentId: selectedId as number,
            personalCode: idValues.personalCode,
            countryCode: idValues.personalCode.includes('-') ? 'LV' : 'LT',
            files,
            documentIds: [document.id],
            timestampPosition: values.timestamp,
            timestampImage: timestampLogo,
            companyCode: document.extension === FileExtensions.Adoc ? code : undefined,
            companyAddress: document.extension === FileExtensions.Adoc ? address : undefined,
            companyName: document.extension === FileExtensions.Adoc ? document.name : undefined,
            language: i18n.language,
            documentName: document.name,
            timestampPage: values.stampPage,
            originUrl: `${window.location.href}`,
            signCoordinates: [positionX, positionY, signPage],
            comment: commentValue,
          } as EParakstsInitSignatureRequest;

          await initEParakstsSignatureSession(eParakstsData);
          clearSignPosition();
          break;

        case Tabs.Approve:
          const approveData = {
            fileExtension: document.extension ?? FileExtensions.Pdf,
            documentId: selectedId as number,
            files,
            documentIds: [document.id],
            timestampPosition: values.timestamp,
            timestampImage: timestampLogo,
            language: i18n.language,
            documentName: document.name,
            timestampPage: values.stampPage,
            comment: commentValue,
          } as ApproveInitRequest;
          await approveDocument(approveData);
          break;

        case Tabs.Acknowledge:
          const acknowledgeData = {
            documentId: selectedId as number,
            comment: commentValue,
          } as AcknowledgeRequest;
          await acknowledgeDocument(acknowledgeData);
          setCanAcknowledge(false);
          break;

        case Tabs.SignaturePad:
          const model = {
            documentId: document.id,
            stamp: stamp ? stamp.split(',')[1] : '',
            x,
            y,
            xText,
            yText,
            page,
            width: finalWidth,
            height: finalHeight,
            textStamp: textStamp ? textStamp.split(',')[1] : '',
            textWidth,
            textHeight,
          };

          try {
            await signDocumentOnPad(model);
            eventBus.publish({
              topic: EventTypes.DocumentSigned,
              payload: {},
            });
          } catch (error) {
            clearSignatureReqModelState();
            clearSignPosition();
          }
          clearSignatureReqModelState();
          clearSignPosition();
          break;

        default:
          return;
      }

      setSubmitting(false);
      setAllowSign(false);
      setIsLoading(false);
      resetForm();
    } catch (error) {
      setAllowSign(true);
      setIsLoading(false);
      resetForm();
      if (isMountedRef.current) {
        setSubmitting(false);
        setAllowSign(false);
      }
    }
  };

  const onSectionClick = () => {
    eventBus.publish({
      topic: EventTypes.SigningBlockClicked,
      payload: {},
    });
  };

  const getTooltipTitle = () =>
    tab === Tabs.Approve
      ? 'documents.buttons.tooltip.approve'
      : canSign
        ? 'documents.buttons.tooltip.sign'
        : 'documents.buttons.tooltip.disabledSign';

  const setTimestamp = (timestamp: string) => {
    if (timestamp !== ' ') {
      setCanUserSelectTimestampPosition(false);
      setFieldValue('timestamp', timestamp);
    }
  };

  const setStampPage = (stampPage: string) => {
    if (stampPage !== ' ') {
      setCanUserSelectTimestampPagePosition(false);
      setFieldValue('stampPage', stampPage);
    }
  };

  useEffect(() => {
    if (settings?.timestampPosition) {
      setTimestamp(settings.timestampPosition);
    }

    if (settings?.timestampPagePosition) {
      setStampPage(settings.timestampPagePosition);
    }
  }, [settings]);

  const btnText = () => {
    if (tab === Tabs.Acknowledge) {
      return t('documents.buttons.acknowledge');
    }
    return t(tab === Tabs.Approve ? 'documents.buttons.approve' : 'documents.buttons.sign');
  };

  const isDisabledBtn = () => {
    if (tab === Tabs.Approve) {
      return false;
    }
    if (tab === Tabs.SignaturePad) {
      return !stamp;
    }
    return !canSign;
  };

  return (
    <>
      <ControlCodePopup onClose={clearSession} />
      {allowSign && needSign && (
        <Box onClick={onSectionClick}>
          <Card>
            <Accordion defaultExpanded sx={{ p: 3, display: 'block' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 3 }}>
                <Typography variant="h6"> {t('documents.signing')}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 3, pt: 0 }}>
                {/* ---- Unreachable code. If you have any questions, contact Giedrė Klimaitė. ----*/}
                {false && (showTabs.MOBILE_ID || showTabs.SMART_ID || showTabs.EPARAKSTS) && (
                  <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
                    {t('documents.signDragTex')}
                  </Typography>
                )}

                <TabContext value={tab || ''}>
                  <TabList variant="scrollable" sx={{ mb: 2 }} onChange={handleTabChange}>
                    {showTabs.MOBILE_ID && !canAcknowledge && (
                      <Tab
                        sx={{
                          textTransform: 'none',
                          marginRight: isMobile ? '8px !important' : undefined,
                        }}
                        label={
                          <Box>
                            <img
                              width={24}
                              height={24}
                              style={{ margin: '8px auto' }}
                              src="/icons/icon_mobile_id.svg"
                              alt="mobile_id"
                            />
                            {t('shared.fields.mobileId')}
                          </Box>
                        }
                        value={Tabs.MobileId}
                      />
                    )}

                    {showTabs.SMART_ID && !canAcknowledge && (
                      <Tab
                        sx={{
                          textTransform: 'none',
                          marginRight: isMobile ? '8px !important' : undefined,
                          // display: loginMethod === 'SMART_ID' ? 'block' : 'none',
                        }}
                        label={
                          <Box>
                            <img
                              width={24}
                              height={24}
                              style={{ margin: '8px auto' }}
                              src="/icons/icon_smart_id.svg"
                              alt="smart_id"
                            />
                            {t('shared.fields.smartId')}
                          </Box>
                        }
                        value={Tabs.SmartId}
                      />
                    )}

                    {showTabs.EPARAKSTS && !canAcknowledge && (
                      <Tab
                        sx={{
                          textTransform: 'none',
                          marginRight: isMobile ? '8px !important' : undefined,
                          // display: loginMethod === 'EPARAKSTS' ? 'block' : 'none',
                        }}
                        label={
                          <Box>
                            <img
                              width={24}
                              height={24}
                              style={{ margin: '8px auto' }}
                              src="/icons/icon_eparakst.svg"
                              alt="eparaksts"
                            />
                            {t('shared.fields.eparaksts')}
                          </Box>
                        }
                        value={Tabs.EParaksts}
                      />
                    )}

                    {showTabs.APPROVE && (
                      <Tab
                        sx={{
                          textTransform: 'none',
                          marginRight: isMobile ? '8px !important' : undefined,
                        }}
                        label={
                          <Box>
                            <img
                              width={28}
                              height={28}
                              style={{ margin: '3px auto 5px auto' }}
                              src="/icons/icon_approve.svg"
                              alt="approve"
                            />
                            {t('documents.approve')}
                          </Box>
                        }
                        value={Tabs.Approve}
                      />
                    )}

                    {showTabs.SIGNATURE_PAD && (
                      <Tab
                        sx={{
                          textTransform: 'none',
                          marginRight: isMobile ? '8px !important' : undefined,
                        }}
                        label={
                          <Box>
                            <img
                              width={28}
                              height={28}
                              style={{ margin: '3px auto 5px auto' }}
                              src="/icons/icon_handwritten_sign.svg"
                              alt="hand written sign"
                            />
                            {t('documents.signaturePad')}
                          </Box>
                        }
                        value={Tabs.SignaturePad}
                      />
                    )}

                    {showTabs.ACKNOWLEDGE && (
                      <Tab
                        sx={{
                          textTransform: 'none',
                          marginRight: isMobile ? '8px !important' : undefined,
                        }}
                        label={
                          <Box>
                            <img
                              width={28}
                              height={28}
                              style={{ margin: '3px auto 5px auto' }}
                              src="/icons/icon_approve.svg"
                              alt="acknowledge"
                            />
                            {t('documents.acknowledge')}
                          </Box>
                        }
                        value={Tabs.Acknowledge}
                      />
                    )}
                  </TabList>

                  <Box>
                    <Stack>
                      <Box>
                        {showTabs.MOBILE_ID && !canAcknowledge && !showTabs.SIGNATURE_PAD && (
                          <TabPanel value={Tabs.MobileId}>
                            <DocumentMobileIdSign
                              submitTrigger={submitMobileIdTrigger}
                              submitValues={submitValues}
                              isAdoc={document?.extension === 'adoc'}
                            />
                          </TabPanel>
                        )}

                        {showTabs.SMART_ID && !canAcknowledge && !showTabs.SIGNATURE_PAD && (
                          <TabPanel value={Tabs.SmartId}>
                            <DocumentSmartIdSign
                              submitTrigger={submitSmartIdTrigger}
                              submitValues={submitValues}
                              isAdoc={document?.extension === 'adoc'}
                            />
                          </TabPanel>
                        )}

                        {showTabs.EPARAKSTS && !canAcknowledge && !showTabs.SIGNATURE_PAD && (
                          <TabPanel value={Tabs.EParaksts}>
                            <DocumentEParakstsSign
                              submitTrigger={submitEParakstsTrigger}
                              submitValues={submitValues}
                            />
                          </TabPanel>
                        )}
                        {showTabs.SIGNATURE_PAD && !canAcknowledge && (
                          <TabPanel value={Tabs.SignaturePad}>
                            {stamp !== undefined && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  gap: 2,
                                  width: '100%',
                                }}
                              >
                                <Box
                                  sx={{
                                    maxHeight: '216px',
                                    maxWidth: '200px',
                                    backgroundColor: '#fff',
                                    p: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <img
                                    src={`${textStamp}`}
                                    alt="hand-sign-text"
                                    width={`${textWidth || 100}px`}
                                    height="16px"
                                    style={{ objectFit: 'scale-down' }}
                                  />
                                  <img
                                    src={`${stamp}`}
                                    alt="hand-sign"
                                    width={`${finalWidth || 100}px`}
                                    height={`${finalHeight}`}
                                    style={{ objectFit: 'scale-down' }}
                                  />
                                </Box>

                                <IconButton
                                  id="xi1pcg94"
                                  onClick={() => {
                                    clearSignatureReqModelState();
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            )}

                            {stamp === undefined && (
                              <Grid container spacing={3}>
                                <Grid item xs={6}>
                                  <DocumentSignaturePad />
                                </Grid>
                              </Grid>
                            )}
                          </TabPanel>
                        )}

                        {showTabs.APPROVE && <TabPanel value={Tabs.Approve} />}

                        {showTabs.ACKNOWLEDGE && <TabPanel value={Tabs.Acknowledge} />}
                      </Box>

                      <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                          {document?.extension === FileExtensions.Pdf &&
                            tab !== Tabs.Acknowledge &&
                            tab !== Tabs.SignaturePad && (
                              <TextField
                                disabled={!canUserSelectTimestampPosition}
                                select
                                fullWidth
                                size="small"
                                label={t('documents.fields.timestampPosition')}
                                {...getFieldProps('timestamp')}
                                sx={{ mb: 3, mt: 3 }}
                                id="301032mj"
                              >
                                <MenuItem value={TimeStamps.TopLeft}>
                                  {t('documents.timestampPositionValues.topLeft')}
                                </MenuItem>

                                {/* ---- Temporarily disabled. If you have any questions, contact Mindaugas. ----*/}
                                {/* <MenuItem value={TimeStamps.BottomLeft}>
                                  {t('documents.timestampPositionValues.bottomLeft')}
                                </MenuItem> */}
                                {/* <MenuItem value={TimeStamps.TopRight}>
                                  {t('documents.timestampPositionValues.topRight')}
                                </MenuItem> */}

                                <MenuItem value={TimeStamps.BottomRight}>
                                  {t('documents.timestampPositionValues.bottomRight')}
                                </MenuItem>
                              </TextField>
                            )}

                          {document?.extension === FileExtensions.Pdf &&
                            tab !== Tabs.Acknowledge &&
                            tab !== Tabs.SignaturePad && (
                              <TextField
                                disabled={!canUserSelectTimestampPagePosition}
                                select
                                fullWidth
                                size="small"
                                label={t('documents.fields.timestampPagePosition')}
                                {...getFieldProps('stampPage')}
                              >
                                <MenuItem value={TimeStampPages.First}>
                                  {t('documents.timestampPagePositionValues.firstPage')}
                                </MenuItem>
                                <MenuItem value={TimeStampPages.Last}>
                                  {t('documents.timestampPagePositionValues.lastPage')}
                                </MenuItem>
                              </TextField>
                            )}

                          <Grid
                            container
                            spacing={3}
                            direction={isMobile ? 'column' : 'row'}
                            justifyContent="space-between"
                            mt={1}
                          >
                            {/* ---- Unreachable code. If you have any questions, contact Giedrė Klimaitė. ----*/}
                            {false &&
                              tab !== Tabs.Acknowledge &&
                              tab !== Tabs.SignaturePad &&
                              tab !== Tabs.Approve && (
                                <Grid item xs={12}>
                                  <Button
                                    type="button"
                                    variant="text"
                                    sx={{ lineHeight: '1rem', py: 1 }}
                                    onClick={handleSignPosition}
                                    id="p3y1bire"
                                  >
                                    {t('documents.chooseSignPosition')}
                                  </Button>
                                </Grid>
                              )}

                            {/* ----- Signing comment ----- */}

                            <ModuleCheck module={ModuleTypes.SigningComment}>
                              <Grid item xs={12} sx={{ my: 2 }}>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  placeholder={t('shared.fields.signingCommment')}
                                  label={t('shared.fields.signingCommment')}
                                  size="small"
                                  multiline
                                  name="comment"
                                  value={commentValue}
                                  onChange={handleCommentValueChange}
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                  helperText={`${commentValue ? commentValue.length : 0}/100 ${t('forms.validations.numberOfCharacters')}`}
                                  maxRows={4}
                                />
                              </Grid>
                            </ModuleCheck>

                            {isOwner() && (
                              <ModuleCheck module={ModuleTypes.VoidDocuments}>
                                <PermissionCheck permissions={[Permissions.VoidDocument]}>
                                  <Grid item xs>
                                    <LoadingButton
                                      fullWidth
                                      type="button"
                                      onClick={() => setIsRejectionDialogOpen(true)}
                                      variant="outlined"
                                      loading={isLoading}
                                      id="n6cda3m5"
                                    >
                                      {t('documents.buttons.void')}
                                    </LoadingButton>
                                    {isRejectionDialogOpen && (
                                      <DocumentVoidDialog
                                        handleClose={handleRejectionDialogClose}
                                      />
                                    )}
                                  </Grid>
                                </PermissionCheck>
                              </ModuleCheck>
                            )}
                            {!isOwner() && (
                              <Grid item xs>
                                <LoadingButton
                                  fullWidth
                                  type="button"
                                  onClick={() => setIsRejectionDialogOpen(true)}
                                  variant="outlined"
                                  loading={isLoading}
                                  id="mysjgf38"
                                >
                                  {t('documents.buttons.reject')}
                                </LoadingButton>
                                {isRejectionDialogOpen && (
                                  <DocumentRejectionDialog
                                    handleClose={handleRejectionDialogClose}
                                  />
                                )}
                              </Grid>
                            )}

                            <Grid item xs>
                              <Tip titleLocale={getTooltipTitle()}>
                                <LoadingButton
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                  loading={isLoading}
                                  disabled={isDisabledBtn()}
                                  id="zecmav3p"
                                >
                                  {btnText()}
                                </LoadingButton>
                              </Tip>
                            </Grid>
                          </Grid>
                        </Form>
                      </FormikProvider>
                    </Stack>
                  </Box>
                </TabContext>
              </AccordionDetails>
            </Accordion>
          </Card>
        </Box>
      )}

      {!allowSign &&
        needSign &&
        isOwner() &&
        document &&
        document.voided === null &&
        document.status !== 1 && (
          <Box onClick={onSectionClick}>
            <Card>
              <Accordion defaultExpanded sx={{ p: 3 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 3 }}>
                  <Typography variant="h6"> {t('documents.signing')}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 3, pt: 0 }}>
                  <TabContext value={tab || ''}>
                    <Box>
                      <Stack>
                        <FormikProvider value={formik}>
                          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid
                              container
                              spacing={3}
                              direction={isMobile ? 'column' : 'row'}
                              justifyContent="space-between"
                              mt={1}
                            >
                              {isOwner() && (
                                <ModuleCheck module={ModuleTypes.VoidDocuments}>
                                  <PermissionCheck permissions={[Permissions.VoidDocument]}>
                                    <Grid item xs>
                                      <LoadingButton
                                        fullWidth
                                        type="button"
                                        onClick={() => setIsRejectionDialogOpen(true)}
                                        variant="outlined"
                                        loading={isLoading}
                                        sx={{ mt: -3 }}
                                        id="0p2ymmzz"
                                      >
                                        {t('documents.buttons.void')}
                                      </LoadingButton>
                                      {isRejectionDialogOpen && (
                                        <DocumentVoidDialog
                                          handleClose={handleRejectionDialogClose}
                                        />
                                      )}
                                    </Grid>
                                  </PermissionCheck>
                                </ModuleCheck>
                              )}
                              {!isOwner() && (
                                <Grid item xs>
                                  <LoadingButton
                                    fullWidth
                                    type="button"
                                    onClick={() => setIsRejectionDialogOpen(true)}
                                    variant="outlined"
                                    loading={isLoading}
                                    id="u8tlecgq"
                                  >
                                    {t('documents.buttons.reject')}
                                  </LoadingButton>
                                  {isRejectionDialogOpen && (
                                    <DocumentRejectionDialog
                                      handleClose={handleRejectionDialogClose}
                                    />
                                  )}
                                </Grid>
                              )}
                            </Grid>
                          </Form>
                        </FormikProvider>
                      </Stack>
                    </Box>
                  </TabContext>
                </AccordionDetails>
              </Accordion>
            </Card>
          </Box>
        )}
    </>
  );
}
